<template>
  <ValidationObserver ref="contactForm">
    <div class="card card-custom">
      <div class="card-body container">

        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div id="contactFormWizard" class="wizard wizard-3" data-wizard-state="step-first">
            <!--begin: Wizard Body-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <div class="card-body p-0">
                <div class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10">
                  <div class="col-xl-12 col-xxl-12">
                    <!--begin: Wizard Form-->
                    <div class="form mt-0" id="kt_form">

                      <!--begin: ERROR ALERT-->
                      <b-alert v-if="errorAlert" variant="warning" show>
                        <div class="text-dark" v-html="errorAlert"></div>
                      </b-alert>
                      <!--end: ERROR ALERT-->

                      <b-alert v-if="formData && isReadOnly" variant="secondary" show>
                        <div class="text-dark">This form is already submitted and cannot be modified.</div>
                      </b-alert>

                      <b-card v-if="formData.brand" border-variant="primary">

                        <div class="row form-group mb-0">
                          <label class="col-lg-2 col-sm-6 col-xs-12 col-form-label text-right mb-1">First Name<span class="text-danger">*</span></label>
                          <div class="col-lg-4 col-sm-6 col-xs-12 mb-1">
                            <ValidationProvider rules="required" name="firstName" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model.trim="formData.firstName" maxlength="255"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <label class="col-lg-2 col-sm-6 col-xs-12 col-form-label text-right mb-1">Last Name<span class="text-danger">*</span></label>
                          <div class="col-lg-4 col-sm-6 col-xs-12 mb-1">
                            <ValidationProvider rules="required" name="lastName" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model.trim="formData.lastName" maxlength="255"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <label class="col-lg-2 col-sm-6 col-xs-12 col-form-label text-right mb-1">Email<span class="text-danger">*</span></label>
                          <div class="col-lg-4 col-sm-6 col-xs-12 mb-1">
                            <ValidationProvider rules="required" name="email" v-slot="{ errors }">
                              <input type="email" class="form-control" v-model.trim="formData.email" maxlength="100"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <label class="col-lg-2 col-sm-6 col-xs-12 col-form-label text-right mb-1">Phone<span class="text-danger">*</span></label>
                          <div class="col-lg-4 col-sm-6 col-xs-12 mb-1">
                            <ValidationProvider rules="required" name="phone" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model.trim="formData.phone" maxlength="30"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <label class="col-lg-2 col-sm-6 col-xs-12 col-form-label text-right mb-1">Company Name<span class="text-danger">*</span></label>
                          <div class="col-lg-4 col-sm-6 col-xs-12 mb-1">
                            <ValidationProvider rules="required" name="company" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model.trim="formData.company" maxlength="255"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <label v-if="showReferrer" class="col-lg-2 col-sm-6 col-xs-12 col-form-label text-right mb-1">Referrer Name</label>
                          <div v-if="showReferrer" class="col-lg-4 col-sm-6 col-xs-12 mb-1">
                            <ValidationProvider rules="" name="referrer" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model.trim="formData.referrer" maxlength="255"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <label class="col-lg-2 col-sm-6 col-xs-12 col-form-label text-right mb-1">Fleet Size<span class="text-danger">*</span></label>
                          <div class="col-lg-4 col-sm-6 col-xs-12 mb-1">
                            <ValidationProvider rules="required" name="fleetSize" v-slot="{ errors }">
                              <input type="number" min="1" class="form-control" v-model="formData.fleetSize" maxlength="15"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <label class="col-lg-2 col-sm-6 col-xs-12 col-form-label text-right mb-1">Fleet Insurance Renewal Date</label>
                          <div class="col-lg-4 col-sm-6 col-xs-12 mb-1">
                            <ValidationProvider rules="" name="renewalDate" v-slot="{ errors }">
                              <b-form-datepicker
                                  class="form-control form-control-sm"
                                  v-model="formData.insuranceRenewalDate"
                                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                  :min="today" locale="en-AU" :disabled="isReadOnly"
                              >
                              </b-form-datepicker>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                      </b-card>

                      <!--begin: Wizard Actions -->
                      <div class="d-flex justify-content-end border-top pt-8" v-if="formData.brand">
                        <div>
                          <button v-if="!isReadOnly"  class="btn btn-primary font-weight-bold text-uppercase px-8 py-4"
                                  @click.prevent="submitForm"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      <!--end: Wizard Actions -->
                    </div>
                    <!--end: Wizard Form-->
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-disabled {
  cursor: not-allowed !important;
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

</style>

<script>
import _ from "lodash";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import BrandService from "@/core/services/brand.service";

export default {
  name: "ContactForm",

  data() {
    return {
      serverUrl: null,
      formId: null,
      formData: {},
      showReferrer: true,
      errorAlert: null
    };
  },

  mounted() {
    this.startPageLoading();

    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV === 'development') {
      this.serverUrl = `http://localhost:3000/services`;
    }
    this.$apiService.setServerUrl(this.serverUrl);

    // Extract information from URL params
    const queryParams = this.$route.query;
    if (!queryParams.brand && !queryParams.formId) {
      this.handleError("Unauthorised access!");
      return;
    }

    if(queryParams.brand) {
      // Set UI Brand
      this.$set(this.formData, 'brand', _.toUpper(queryParams.brand));
      BrandService.setUIBrand(this.formData.brand);
    }

    if(queryParams.referrer) {
      this.showReferrer = false;
      this.$set(this.formData, 'referrer', queryParams.referrer);
    }

    // Extract data from URL params
    this.formId = queryParams.formId || null;

    this.$store.commit("setWizardTitle", `Contact Details Form`);

    // Get form data.
    this.getFormData();
  },

  computed: {
    isReadOnly: function () {
      if(this.formData && this.formData.formStatus) {
        const status = this.formData.formStatus;
        return status !== 'Initialised' && status !== 'Incomplete';
      }
      return false;
    },

    today: function () {
      return new Date();
    }
  },

  methods: {

    getFormData: function () {
      if(this.formId) {
        this.$apiService.get(`${this.serverUrl}/contact/form/${this.formId}`)
            .then(({ data }) => {
              this.formData = data.result;

              // Set UI Brand
              BrandService.setUIBrand(this.formData.brand);

              this.stopPageLoading();
            })
            .catch((error) => {
              this.handleError(error);
            });
      }
      else {
        this.stopPageLoading();
      }
    },

    submitForm: async function () {
      if(this.isReadOnly) {
        return;
      }

      const isFormValid = await this.$refs.contactForm.validate();
      if(!isFormValid) {
        return;
      }

      this.startPageLoading();
      this.$apiService.post(`${this.serverUrl}/contact/form/submit`, this.formData)
          .then(() => {
            this.stopPageLoading();

            Swal.fire({
              titleText: "Submission Successful",
              html: `<p>Thank you for submitting this form.</p><br/>
                     <span class="font-weight-bold">Please close this browser window.</span>`,
              icon: "success",
              width: "40rem",
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: false
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function(errorObj) {
      this.stopPageLoading();
      let errorMsg = null;

      if(errorObj) {
        let responseStatus = _.get(errorObj, "response.status");
        let errorMessage = errorObj.message || '';
        if (responseStatus === 401 || errorMessage.includes("status code 401") ||
            responseStatus === 403 || errorMessage.includes("status code 403")
        ) {
          errorMsg = "This session is invalid or expired. Please close this window.";
        }
        else {
          let responseResult = _.get(errorObj, "response.data.result");
          if (_.isString(responseResult)) {
            let applicationErrorPrefix = "UI_ERROR:";

            if (responseResult.startsWith(applicationErrorPrefix)) {
              errorMsg = responseResult.substring(applicationErrorPrefix.length, responseResult.length);
            }
          }
        }
      }
      if (!errorMsg) {
        console.log(errorObj);
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
};
</script>
